module.exports = {
  development: {
    MODE: "development",
    VUE_APP_NAME: "development",
    VUE_BASE_URL: "https://api.jybtech.cn",
    VUE_APP_RENTALMARKET: "https://zulin.jybtech.cn",
    VUE_APP_REVIEW: "https://api.jybtech.cn/sgb-review",
    VUE_APP_GROUNPBUYING: "https://jicai.jybtech.cn",
    VUE_APP_TEAM_URL: "https://gongdui.jybtech.cn",
    VUE_APP_COOKIE: ".jybtech.cn",
    VUE_APP_TENDERURL: " https://zhaocai.jybtech.cn",
    VUE_APP_MENUURL: "https://api.jybtech.cn/sgb-cailiao/menu/getList",
    VUE_APP_MATERIALSURL: " https://dev.jybtech.cn",
    VUE_APP_LOGISTICS: "https://api.jybtech.cn/logistics-test",
    VUE_APP_LOGISTICSURL: "http://wuliu.jybtech.cn",
    VUE_APP_MECHANICAL: "https://api.jybtech.cn/sgb-jixie",
    VUE_APP_APIUSER: "https://api.jybtech.cn/memberCenter",
    VUE_APP_APIUSERURL: "https://user.jybtech.cn",
    VUE_APP_PERSONURL: "https://user.jybtech.cn",
    VUE_APP_APIMATERIALS: "https://api.jybtech.cn/sgb-cailiao",
    VUE_APP_APIMATERIALS_NEW: "https://api.jybtech.cn/shigongbang-es",
    VUE_APP_APIPACT: "https://b3fxhr.natappfree.cc",
    VUE_APP_APIMACHINERY: "https://api.jybtech.cn/sgb-jixie",
    VUE_APP_APIEQUIPMENT: "https://api.jybtech.cn/sgb-shebei",
    VUE_APP_APIRECRUITMENT: "https://api.jybtech.cn/sgb-zhaocai",
    VUE_APP_KNOWLEDGEsHARING: "http://gcxy.jybtech.cn",
    VUE_APP_API_APISTORE: "https://personal-management.jybtech.cn:9292",
    VUE_APP_TEAM: "https://api.jybtech.cn/sgb-gongdui",
    VUE_APP_QIGUANBANG: "https://qiguanbang.qgbtech.cn",
    VUE_APP_API: "https://api.jybtech.cn",
    VUE_APP_LOGINAPI: "https://api.jybtech.cn",
    VUE_APP_AUTHURLTYPE: "/authentication",
    VUE_APP_ENTERPRISE: "/enterprise-managerment",
    VUE_APP_QIGUAN: "/sgb-app-manager",
    VUE_APP_AUDIT: "https://api.jybtech.cn/sgb-audit",
    VUE_APP_CONSTRUCTIONPOWER: "https://api.jybtech.cn/sgb-jsll",
    VUE_APP_APIKNOWLEDGE_SHARING: "https://api.jybtech.cn/sgb-xueyuan",
    VUE_APP_FOCUSPURCHASES: "https://api.jybtech.cn/groupbuying-test", //测试服务器地址
    VUE_APP_GROUPBUY: "https://api.jybtech.cn/groupbuying-test",
    VUE_APP_ALL_JURI: "https://api.jybtech.cn",
    qiye_RPOUTE_API: "https://qiye.jybtech.cn",
    qiye_SERVICE: "http://qiyefw.jybtech.cn",
    VUE_APP_RECRUIT: "http://recruitment.jybtech.cn",
    VUE_APP_home: "https://home.jybtech.cn",
    VUE_APP_BURYINGPOINT: "https://api.jybtech.cn/shigongbang-analysis", //埋点地址
    VUE_APP_ADVER: "https://api.jybtech.cn",
    VUE_APP_SYNC_TOKEN: "https://sync.jybtech.cn",
    VUE_APP_HUIYUAN: "https://vip.jybtech.cn",
    VUE_SERVERNAME_JYBUSERPAGE: "https://api.jybtech.cn/jyb-user-page",
    VUE_INVERTEMT: "https://investment.jybtech.cn",
    VUE_TRIP: "https://trip.jybtech.cn",
    VUE_GOODSPERCHASE: "https://api.jybtech.cn/sgb-member",
    VUE_APIBYINVESTMENTS: "https://api.jybtech.cn/invite-investments",
    VUE_FINANCESERVICE:"https://finance.jybtech.cn",
    VUE_APP_NOTIFYWS: "wss://tio.jybtech.cn",
    VUE_APP_GGBBASEAPI: "https://api.qgbtech.cn",
    VUE_APP_WEBSITE:"https://website.jybtech.cn/"
  },
  pre: {
    MODE: "pre",
    VUE_APP_NAME: "pre",
    VUE_BASE_URL: "https://api.jingyingbang.net",
    VUE_APP_LOGISTICS: "https://api.jingyingbang.net/logistics",
    VUE_APP_REVIEW: "https://api.jingyingbang.net/sgb-review",
    VUE_APP_API: "https://api.jingyingbang.net",
    VUE_APP_MECHANICAL: "https://api.jingyingbang.net/sgb-jixie",
    VUE_APP_COOKIE: ".jingyingbang.net",
    VUE_APP_PERSON: "https://user.jingyingbang.net",
    VUE_APP_MENUURL: "https://api.jingyingbang.net/sgb-cailiao/menu/getList",
    VUE_APP_PERSONURL: "https://user.jingyingbang.net",
    VUE_APP_APIMATERIALS: "https://api.jingyingbang.net/sgb-cailiao",
    VUE_APP_APIMATERIALS_NEW: "https://api.jingyingbang.net/shigongbang-es",
    VUE_APP_APIUSER: "https://api.jingyingbang.net/memberCenter",
    VUE_APP_MATERIALSURL: "https://shangpin.jingyingbang.net",
    VUE_APP_LOGINAPI: "https://api.jingyingbang.net",
    VUE_APP_ENTERPRISE: "/enterprise-managerment",
    VUE_APP_QIGUANBANG: "https://qgb.jingyingbang.net",
    VUE_APP_QIGUAN: "/sgb-app-manager",
    VUE_APP_AUTHURLTYPE: "/authentication",
    VUE_APP_APIEQUIPMENT: "https://api.jingyingbang.net/sgb-shebei",
    VUE_APP_APIRECRUITMENT: "https://api.jingyingbang.net/sgb-zhaocai",
    VUE_APP_APIMACHINERY: "https://api.jingyingbang.net/sgb-jixie",
    VUE_APP_APIKNOWLEDGE_SHARING: "https://api.jingyingbang.net/sgb-xueyuan",
    VUE_APP_TEAM: "https://api.jingyingbang.net/sgb-gongdui",
    VUE_APP_CONSTRUCTIONPOWER: "https://api.jingyingbang.net/sgb-jsll",
    VUE_APP_TENDERURL: "https://zhaocai.jingyingbang.net",
    VUE_APP_RENTALMARKET: "https://zulin.jingyingbang.net",
    VUE_APP_BUILDPOWER: "https://liliang.jingyingbang.net",
    VUE_APP_FINANCALSERVICE: "https://jinrong.jingyingbang.net",
    VUE_APP_KNOWLEDGEsHARING: "https://fenxiang.jingyingbang.net",
    VUE_APP_GROUNPBUYING: "https://jicai.jingyingbang.net",
    VUE_APP_TEAM_URL: "https://tuandui.jingyingbang.net",
    VUE_APP_LOGISTICSURL: "https://wuliu.jingyingbang.net",
    VUE_APP_ALL_JURI: "https://api.jingyingbang.net",
    VUE_APP_FOCUSPURCHASES: "https://api.jingyingbang.net/groupbuying",
    VUE_APP_GROUPBUY: "https://api.jingyingbang.net/groupbuying",
    qiye_RPOUTE_API: "https://qiye.jingyingbang.net",
    qiye_SERVICE: "http://qiyefw.jingyingbang.net",
    VUE_APP_RECRUIT: "http://zhaopin.jingyingbang.net",
    VUE_APP_home: "https://www.jingyingbang.net",
    VUE_APP_BURYINGPOINT: "https://api.jingyingbang.net/shigongbang-analysis", //埋点地址
    VUE_APP_ADVER: "https://api.jingyingbang.net",
    VUE_APP_SYNC_TOKEN: "https://sync.jingyingbang.net",
    VUE_APP_HUIYUAN: "https://vip.jingyingbang.net",
    VUE_SERVERNAME_JYBUSERPAGE: "https://api.jingyingbang.net/jyb-user-page",
    VUE_INVERTEMT: "https://investments.jingyingbang.net",
    VUE_TRIP: "https://trip.jingyingbang.net",
    VUE_GOODSPERCHASE: "https://api.jingyingbang.net/sgb-member",
    VUE_APIBYINVESTMENTS: "https://api.jingyingbang.net/invite-investments",
    VUE_FINANCESERVICE:"https://finance.jingyingbang.net",
    VUE_APP_GGBBASEAPI: "https://api.zhineng.jingyingbang.net",
    VUE_APP_WEBSITE:"https://website.jingyingbang.net/"
  },
  production: {
    MODE: "production",
    VUE_APP_NAME: "production",
    VUE_BASE_URL: "https://gateway.jingyingbang.com",
    VUE_APP_LOGISTICS: "https://gateway.jingyingbang.com/logistics",
    VUE_APP_REVIEW: "https://gateway.jingyingbang.com/sgb-review",
    VUE_APP_API: "https://gateway.jingyingbang.com",
    VUE_APP_MECHANICAL: "https://gateway.jingyingbang.com/sgb-jixie",
    VUE_APP_COOKIE: ".jingyingbang.com",
    VUE_APP_PERSON: "https://user.jingyingbang.com",
    VUE_APP_MENUURL:
      "https://gateway.jingyingbang.com/sgb-cailiao/menu/getList",
    VUE_APP_PERSONURL: "https://user.jingyingbang.com",
    VUE_APP_APIMATERIALS: "https://gateway.jingyingbang.com/sgb-cailiao",
    VUE_APP_APIMATERIALS_NEW: "https://gateway.jingyingbang.com/shigongbang-es",
    VUE_APP_APIUSER: "https://gateway.jingyingbang.com/memberCenter",
    VUE_APP_MATERIALSURL: "https://shangpin.jingyingbang.com",
    VUE_APP_LOGINAPI: "https://gateway.jingyingbang.com",
    VUE_APP_ENTERPRISE: "/enterprise-managerment-release",
    VUE_APP_QIGUANBANG: "https://qiguanbang.com",
    VUE_APP_QIGUAN: "/sgb-app-manager",
    VUE_APP_AUTHURLTYPE: "/authentication",
    VUE_APP_APIEQUIPMENT: "https://gateway.jingyingbang.com/sgb-shebei",
    VUE_APP_APIRECRUITMENT: "https://gateway.jingyingbang.com/sgb-zhaocai",
    VUE_APP_APIMACHINERY: "https://gateway.jingyingbang.com/sgb-jixie",
    VUE_APP_APIKNOWLEDGE_SHARING:
      "https://gateway.jingyingbang.com/sgb-xueyuan",
    VUE_APP_TEAM: "https://gateway.jingyingbang.com/sgb-gongdui",
    VUE_APP_CONSTRUCTIONPOWER: "https://gateway.jingyingbang.com/sgb-jsll",
    VUE_APP_TENDERURL: "https://zhaocai.jingyingbang.com",
    VUE_APP_RENTALMARKET: "https://zulin.jingyingbang.com",
    VUE_APP_BUILDPOWER: "https://liliang.jingyingbang.com",
    VUE_APP_FINANCALSERVICE: "https://jinrong.jingyingbang.com",
    VUE_APP_KNOWLEDGEsHARING: "https://fenxiang.jingyingbang.com",
    VUE_APP_GROUNPBUYING: "https://jicai.jingyingbang.com",
    VUE_APP_TEAM_URL: "https://tuandui.jingyingbang.com",
    VUE_APP_LOGISTICSURL: "https://wuliu.jingyingbang.com",
    VUE_APP_ALL_JURI: "https://gateway.jingyingbang.com",
    VUE_APP_FOCUSPURCHASES: "https://gateway.jingyingbang.com/groupbuying",
    VUE_APP_GROUPBUY: "https://gateway.jingyingbang.com/groupbuying",
    qiye_RPOUTE_API: "https://qiye.jingyingbang.com",
    qiye_SERVICE: "http://qiyefw.jingyingbang.com",
    VUE_APP_RECRUIT: "http://zhaopin.jingyingbang.com",
    VUE_APP_home: "https://www.jingyingbang.com",
    VUE_APP_BURYINGPOINT:
      "https://gateway.jingyingbang.com/shigongbang-analysis", //埋点地址
    VUE_APP_ADVER: "https://gateway.jingyingbang.com",
    VUE_APP_SYNC_TOKEN: "https://sync.jingyingbang.com",
    VUE_APP_HUIYUAN: "https://vip.jingyingbang.com",
    VUE_SERVERNAME_JYBUSERPAGE:
      "https://gateway.jingyingbang.com/jyb-user-page",
    VUE_INVERTEMT: "https://investments.jingyingbang.com",
    VUE_TRIP: "https://trip.jingyingbang.com",
    VUE_GOODSPERCHASE: "https://gateway.jingyingbang.com/sgb-member",
    VUE_APIBYINVESTMENTS: "https://gateway.jingyingbang.com/invite-investments",
    VUE_FINANCESERVICE:"https://finance.jingyingbang.com", // 金融服务
    VUE_APP_NOTIFYWS: "wss://tio.jingyingbang.com",
    VUE_APP_GGBBASEAPI: "https://gateway.jingyingbang.com",
    VUE_APP_WEBSITE:"https://website.jingyingbang.com/"
  }
};

